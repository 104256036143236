:root {
  --s-textcolor: #232935;
  --s-sitecolor: #0AD705;
  --s-linkcolor: #232935;
  --s-linkcolor-hov: #0AD705;
  --s-btn-border: #232935;
  --s-btn-bg: #F4F4F4;
  --s-btn-bg-hov: #232935;
  --s-btn-txt: #232935;
  --s-btn-txt-hov: #F4F4F4;
  --s-getintouch-icon: url(/images/icon-mail-greyblue.svg);
  --s-getintouch-icon-hov: url(/images/icon-mail-green.svg);
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .button:hover,
  .button:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .button:active {
    background-color: var(--s-btn-bg-hov);
  }
}
.area--one .unit {
  width: 101.85185185%;
  margin-left: 0.92592593%;
  margin-right: 0.92592593%;
}
.area--one .unit {
  width: 98.18181818%;
  margin-left: 0.90909091%;
  margin-right: 0.90909091%;
}
.area--one .unitThree {
  width: 31.45454545%;
}
.area--column .unitOne--1-1-indentLeft .unit__body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}
.area--column .unitOne--1-1-indentLeft .part--slim {
  width: calc(50% - 10px);
}
.area--column .unitOne--1-1-indentLeft .part--slim.pict {
  padding-bottom: calc(var(--spaceTotal) * 1);
}
/*# sourceMappingURL=./screen-medium.css.map */